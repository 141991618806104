import React from "react";
import styled from "styled-components";
import Card from "./Card";

const StyledBanner = styled.div`


.bannerwrapper {
    display: grid;
    display: -ms-grid;
    /* set a container type for container units */
    container-type: inline-size;
    grid-template-columns: 19fr 16fr; /* Adjusts to content and available space */
    -ms-grid-columns: 19fr 16fr;
    -ms-grid-rows: auto 0rem auto 0rem 4vmin;
    grid-template-rows: auto auto 4vmin; /* Rows size adjust based on their content */
            grid-template-areas: 
      "topleft topright"
      "middleleft topright"
      "bottomleft topright";
    gap: 0rem;
  }


    .banner__image {
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
      grid-column: 1 / span 2;
      -ms-grid-row: 1;
      -ms-grid-row-span: 3;
      grid-row: 1 / span 3;
      width: 16rem;
      max-width: 100%;
      max-height: 100%;
      -ms-grid-row-align: end;
          align-self: end;
      -ms-grid-column-align: start;
          justify-self: start;
      position: relative;
      right: -27vi;
  }

  .banner__image div[data-placeholder-image] {
    background-color: rgba(0,0,0,0) !important;
  }


  .banner__headline {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: topleft;
    font-size: calc(23px + 1.4vi);
  }

  .headline {
    grid-area: topleft;
    line-height: 110%;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
    font-style: bold;
    font-weight: 600;
  }

  .serif {
    font-family: Georgia, serif;
    font-variant: normal;
  }

  .features {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-align: start;
        justify-self: start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 0.5rem;
    grid-area: middleleft;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: 1.5vi;
  }

  .features>.cta-primary {
    margin-top: 0.9rem;
  }

  .features a.rating>img {
    -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    height: 2rem;
  }
  .features a.rating>span {
    display: none;
  }

  .features a.rating>div {
    -ms-grid-column: 1;
    grid-column: 1;
  }

 

  .emailwrapper {
    grid-area: topright;
    margin-top: 1rem;
    -ms-grid-column-align: center;
        justify-self: center;
  }

  @media all and (max-width: 1080px) {
    .banner__image {
      right: -45vi;
  }

  }

  @media all and (max-width: 550px) {
    .banner__image {
      width: 48vi;
    }

    .rating {
      display: none;
    }

  }
`;

const Banner = ({ props, children }) => (
  <Card>
    <StyledBanner className="banner">
      <div className="bannerwrapper">{children}</div>
    </StyledBanner>
  </Card>
);

export default React.memo(Banner);
