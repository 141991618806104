import React from "react";
import styled from "styled-components";

const StyledCard = styled.div`
  background: var(--background);
  width: 100%;
  height: auto;
  padding: 4vmin;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);

  .card:hover {
    background: rgba(0, 0, 0, 0.07);
  }

  .card__heading {
    font-family: -apple-system, Arial, "Helvetica", "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", sans-serif;
    margin: 0.5rem 0rem;
    color: var(--foreground);
  }

  /* this is css for a child component 'Multilinks' */
  .hotlinklist__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media all and (min-width: 1601px) {
    .hotlinklist__container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
  }}

  @media all and (min-width: 1001px) and (max-width: 1600px) {
    .hotlinklist__container {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    .hotlinkitem:nth-of-type(1n + 13) {
      display: none;
    }
  }}

  @media all and (max-width: 901px) {
    .hotlinklist__container {
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
    .hotlinkitem:nth-of-type(1n + 13) {
      display: none;
    }
  }}

  @media all and (max-width: 485px) {
    .hotlinklist__container {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    .hotlinkitem:nth-of-type(1n + 7) {
      display: none;
    }
  }}

`;

const Card = ({ children }) => (
  <StyledCard className="card">{children}</StyledCard>
);

export default React.memo(Card);
